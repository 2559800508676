<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ activeStep.pageTitle }}</h3>
      </AppCardHeader>
    </template>
    <form @submit.prevent="submit">
      <AppDynamicForm :fields="startFormFields" :disabled="isLoading" />
      <AppDynamicForm :fields="lastFormFields" :disabled="isLoading" />
      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading">
          Continue
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { computed, reactive } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'
import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '../../stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'

export default {
  name: 'OperatingAddress',
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppIcon,
    IconChevronLeft,
    AppDynamicForm,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const companyDetailsStore = useCompanyDetailsStore()

    const store = useStore()
    const router = useRouter()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    corporateRegistrationStore.activeStepIdx = 2

    const getCountry = companyDetailsStore.companyCountry
    const title =
      getCountry === 'GB' || getCountry === 'MY' ? 'Business address' : 'Operating address'
    corporateRegistrationStore.setActiveStepPageTitle(title)
    const activeStep = corporateRegistrationStore.activeStep

    const fieldGroupName = 'tradingaddress'
    const isSubmitButtonDisabled = computed(() => {
      return !hasFormFields.value || isFormInvalid.value
    })

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    let { fields } = corporateRegistrationStore.getNewCustomerFields(fieldGroupName)

    // Remove country label to fit design
    fields.forEach((field) => {
      if (field.id === 'country') {
        field.label = ''
      }
    })

    if (fields) {
      setupFields(fields, fieldGroupName)
    }

    const stateField = formFields.value.find((field) => field.id === 'state')
    const postalCodeField = formFields.value.find((field) => field.id === 'postalcode')
    let lastFormFields, startFormFields
    if (stateField && postalCodeField) {
      lastFormFields = [stateField, postalCodeField]
      startFormFields = formFields.value.filter(
        (field) => field.id !== 'state' && field.id !== 'postalcode'
      )
    } else {
      startFormFields = formFields.value
    }

    // Sorts input fields on the 'Business address' page according to the order below
    let businessAddressOrderIds = [
      'country',
      'unit',
      'streetnumber',
      'streetname',
      'streettype',
      'line1',
      'suburb',
      'city',
      'postalcode',
    ]
    startFormFields.sort((a, b) => {
      return (
        businessAddressOrderIds.findIndex((item) => item === a.id) -
        businessAddressOrderIds.findIndex((item) => item === b.id)
      )
    })

    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }

    // Delete leading & trailing spaces on input text after onblur event
    formFields.value.forEach((field, i) => {
      if (field.component.name === 'default' || field.component.name === '_default') {
        formFields.value[i]['listeners'] = {
          blur: (evt) => {
            if (field.value === evt.target.value) {
              field.value = cleanTrailingSpace(field.value)
            }
          },
        }
      }
    })

    const submit = async () => {
      isLoading.value = true
      try {
        const errors = await corporateRegistrationStore.submitFieldData({
          group: fieldGroupName,
          formFields,
        })
        if (errors && errors.length > 0) {
          corporateRegistrationStore.showServerErrors({ errors, formFields })
        } else {
          analyticsStore.identify({
            userId: authStore.profileId,
            traits: {
              cityOfOperating: getFieldValue('city'),
              zipcodeOfOperating: getFieldValue('postalcode'),
              stateOfOperating: getFieldValue('state'),
            },
          })

          analyticsStore.track({
            event: 'Address Details Completed',
            traits: {
              addressType: 'Operating',
              referenceSource: 'XEMT Business',
              email: authStore.lastLogin,
            },
          })
          router.push({ name: 'RegisterCurrencyAndVolume' })
        }
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submiting operating address',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      isLoading.value = false
    }

    const goBack = () => {
      router.replace({ name: 'RegisterBusinessAddressSearch' })
    }

    return {
      activeStep,
      isSubmitButtonDisabled,
      submit,
      startFormFields,
      lastFormFields,
      isLoading,
      goBack,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.horizontal {
  @apply flex flex-row -mx-2;
  /deep/ .input {
    @apply flex flex-col w-2/4 mx-2;
  }
}
</style>
